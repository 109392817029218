
const Header = () => {

    return (
        <div
        style={{
            gridColumnStart: "1",
            gridColumnEnd: "3",
            gridRow: "1",
            background: "linear-gradient(to right, rgb(0,0,52), rgb(80,0,86))",
            width: "100%",
            display: "flex",
            alignItems: "center",
            paddingTop: "1rem",
            paddingBottom: "1rem",        
        }}
    >
            <div style={{ width: "5%" }}></div>
            <img src="/freethrowlogo.svg" alt="FREETHROW LOGO" height={"100px"} />
        </div>
    );
}

export default Header;

