
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { FC } from "react";
import { Stats } from "../common/interfaces/Stats";

const ShotStat: FC<Stats> = (props) : JSX.Element => {

    return (
        <>
            <Paper
                sx={{
                    p: 0.5,
                    margin: 0.2,
                    flexGrow: 1,
                    background: "linear-gradient(to right, rgb(0, 0, 52), rgb(80, 0, 86))",
                    color: "white",
                    maxHeight: 80,
                    borderRadius: "10px"
                }}
            >
                <Grid container spacing={2} key={props.rank}>
                    <Grid item>
                        <Typography sx={{ 
                                fontSize: "4rem",
                                fontWeight: 400,
                                fontFamily: "Amazon Ember Heavy",
                                color: "yellow",
                                opacity: 0.8,
                                lineHeight: 1.2,
                                textAlign: "right",
                                paddingLeft: "4px",
                                minWidth: "85px"
                            }} 
                            component="div">
                            {props.rank}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs={4} container direction="column" spacing={2}>
                            <Grid item>
                                <Typography sx={{ 
                                fontSize: "2rem",
                                fontWeight: 400,
                                fontFamily: "Amazon Ember Regular",
                                paddingLeft: "4px",
                                color: "white",
                                lineHeight: 2.5,
                                textAlign: "right",
                                padding: 0,
                                margin: 0
                            }} 
                            component="span">
                                    {props.playerId}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container direction="row" spacing={2}>
                            {props.shots.map((shot, i) => 
                                <Grid item key={i} xs container direction="column">
                                { shot ? 
                                <img src="/shot-success.svg" width={30} alt="Success"
                                    style={{
                                        marginTop: 15
                                    }}
                                    />
                                :
                                <img src="/shot-missed.svg" width={30} alt="Missed" 
                                    style={{
                                        marginTop: 15
                                    }} 
                                    />
                                }
                            </Grid>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <Typography sx={{ 
                                fontSize: "3rem",
                                fontWeight: 400,
                                fontFamily: "Amazon Ember Heavy",
                                paddingLeft: "4px",
                                color: "white",
                                opacity: 0.8,
                                lineHeight: 1.2,
                                textAlign: "right"
                            }} 
                            component="span">
                                {props.score}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );

}

export default ShotStat;