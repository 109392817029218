/* Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.
Licensed under the Amazon Software License (the 'License').
You may not use this file except in compliance with the License.
A copy of the License is located at

  http://aws.amazon.com/asl/

or in the 'license' file accompanying this file. This file is distributed
on an 'AS IS', BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
express or implied. See the License for the specific language governing
permissions and limitations under the License. */

import Header from "./components/Header";
import Footer from "./components/Footer";
import Leaderboard from "./components/Leaderboard";
import DebugInfo from "./components/DebugInfo";
import VideoPlayer from "./components/VideoPlayer";
import { Container, Grid } from '@mui/material';
import type { Players } from "./common/interfaces/Players";
import fetcher from "./common/swr/Fetcher";
import useSWR from "swr";
import "./App.css";

const App = (): JSX.Element => {
  
  // Load from environment variables 
  // Top players API path and API refresh interval 
  const api = process.env.REACT_APP_TOP_PLAYER_API || "https://api.summit-playdemo.sa.aws.dev/v1/players/top?count=10";
  // Insert random number in the URL to force Route53 Health Check
  // api url will be https://12121.api.leaderboard...
  const { data, error } = useSWR<Players>(api, fetcher, { 
      refreshInterval: process.env.REACT_APP_API_REFRESH_INTERVAL ? 
          parseInt(process.env.REACT_APP_API_REFRESH_INTERVAL) : 10000
  });

  if(error) {
    if(data?.top10) {
      console.log('%c%s', 'color: magenta; font-size: 16pt;', 'FREETHROW: API unreachable. Falling back to stale data. \n');
    }
    else {
      console.log('%c%s', 'color: magenta; font-size: 16pt;', 'FREETHROW: API unreachable. No data available. Returning maintenance page. \n');
    
      return (
          <>
              <div className="error-message">
                <img src="/maintenance.png" alt="Maintenance" />
              </div>
          </>            
      );
    }
  }

  if(!data) {
      return (
          <>
            <div className="error-message">
              Loading...
            </div>
          </>
      );
  }

  if(!data?.top10 || data?.top10?.length <= 0) {
    
    console.log('%c%s', 'color: magenta; font-size: 16pt;', 'FREETHROW: No data found in the API response. Returning maintenance page. \n');
    return (
      <>
        <div className="error-message">
          <img src="/maintenance.png" alt="Maintenance" />
        </div>
      </>
    );
  }

  const lb = data?.top10;
  lb.sort((a: { rank: number; }, b: { rank: number; }) => a.rank - b.rank);

  return (
    <>
      <Header/>
        <Grid container item xs>
          <Grid container item xs direction="column">
            <Leaderboard LeaderboardData={lb} />
          </Grid>
          <Grid container item xs={6}>
            <Container> 
              <VideoPlayer />
            </Container>
            <Container>
              <DebugInfo 
                AvailabilityZone={data?.metadata.AvailabilityZone} 
                ProcessedTimestamp={data?.metadata.ProcessedTimestamp} />
            </Container>
          </Grid>
        </Grid>
      <Footer />
    </>    
  );
}

export default App;
