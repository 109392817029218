import ShotStat from "./ShotStat";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import type { Stats } from "../common/interfaces/Stats";


const Leaderboard = (props: any) => {

    return (
        <>
            <Typography variant="h1" sx={{
                    fontSize: "2.5rem",
                    padding: "0.5rem",
                    margin: "0.5rem"
                }}>
                <span style={{
                    textShadow: "-1px -1px 2px #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                    color: "black", 
                    backgroundColor: "black",
                    fontFamily: "Amazon Ember Heavy"
                }}>LEADER</span>
                <span style={{
                    color: "white",
                    fontFamily: "Amazon Ember Heavy"
                }}>BOARD</span>
            </Typography>
            <Paper
                sx={{
                    p: 1,
                    margin: 0.2,
                    flexGrow: 1,
                    background: "linear-gradient(to right, rgb(0, 0, 52), rgb(80, 0, 86))",
                    color: "white",
                    maxHeight: 80,
                    borderRadius: "10px",
                    lineHeight: 0.4
                }}
            >
                <Grid container >
                    <Grid item sx={{
                        width: "100px",
                        textAlign: "center"
                    }}>RANK
                    </Grid>
                    <Grid item xs={11} sm container>
                        <Grid item xs={4} sx={{
                            textAlign: "center"
                        }}>
                            PLAYER ID
                        </Grid>
                        <Grid item xs={6} sx={{
                            textAlign: "center"
                        }}>
                            SHOTS
                        </Grid>
                        <Grid item xs={1} sx={{
                            marginLeft: "20px",
                            textAlign: "right"
                        }}>
                            SCORE
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {props.LeaderboardData?.map((rec: Stats, index: number) => (
                <ShotStat key={index} rank={rec.rank} 
                            playerId={rec.playerId}
                            score={rec.score}
                            shots={rec.shots}
                ></ShotStat>
            ))}                
        </>
    );
            
}

export default Leaderboard;