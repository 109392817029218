
import { Typography } from '@mui/material';
import ReactPlayer from "react-player";

export default function VideoPlayer() {

    return(
        <>
        <Typography variant="h1" sx={{
                      fontSize: "2.5rem",
                      padding: "0.5rem",
                      margin: "0.5rem"
                  }}>
                  <span style={{
                      textShadow: "-1px -1px 2px #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                      color: "black", 
                      backgroundColor: "black",
                      fontFamily: "Amazon Ember Heavy"
                  }}>LIVE</span>
                  <span style={{
                      color: "white",
                      fontFamily: "Amazon Ember Heavy"
                  }}>&nbsp;VIDEO</span>
              </Typography>
              <div className="videowrapper">
                <ReactPlayer width="100%" height="100%" className="react-player"
                  muted autoPlay controls playing
                  url={process.env.REACT_APP_LIVE_VIDEO_URL}  />
              </div>
        </>
    );
}