

const Footer = () => {

    return (
        <div
        style={{
            background: "linear-gradient(to right, rgb(0,0,52), rgb(80,0,86))",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            position: "fixed",
            bottom: "0",
        }}
    >
            <img
                  src="/poweredbylogo.svg"
                  alt="Powered By logo"
                  width="150px"
              />
        </div>
    );
}

export default Footer;





 