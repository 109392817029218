
import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import ChangeHighlight from "react-change-highlight";
import "../App.css";
const DebugInfo = (props: any) => {

    var timezone = process.env.REACT_APP_MOMENT_TIMEZONE || "Australia/Sydney";

    return (
        <>

            <ChangeHighlight highlightClassName="highlightChange">
            <div
                style={{
                    background: "linear-gradient(to right, rgb(0,0,52), rgb(80,0,86))",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "10px",
                    fontFamily: "monospace",
                    color: "white",
                    fontSize: "1.8rem"
                }}
            >
                <div style={{ color: "yellow" }}><b>INFORMATION</b></div>
                <div>Availability Zone: <span ref={React.createRef()}>{props.AvailabilityZone }</span></div>
                    <div style={{ fontSize: "1.2rem" }}>Last Updated: 
                    <Moment unix format="DD-MMM-YYYY hh:mm:ss" tz={timezone}>{props.ProcessedTimestamp}</Moment>
                </div>
            </div>
            </ChangeHighlight>
        </>
    );
}

export default DebugInfo;


/*
            <Paper
                sx={{
                    p: 1,
                    margin: 0.2,
                    flexGrow: 1,
                    background: "linear-gradient(to right, rgb(0, 0, 52), rgb(80, 0, 86))",
                    color: "white",
                    maxHeight: 80,
                    borderRadius: "10px",
                    lineHeight: 0.4
                }}
            >
                <Grid container >
                    <Grid item xs={12} sm container>
                        <Grid item xs={9} sx={{
                            textAlign: "left"
                        }}>
                            Last Updated: { d.toUTCString() }
                        </Grid>
                        <Grid item xs={3} sx={{
                            textAlign: "right"
                        }}>
                            AZ: { data?.metadata.AvailabilityZone }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
*/